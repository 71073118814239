import styled from 'styled-components'
import media from 'constants/breakpoints'
import InViewAnimation from 'components/inViewAnimation'

export const Background = styled.div`
  width: 100%;
  margin-bottom: var(--xl);
  padding: var(--xl) 0 0;
  border-bottom: 1px solid transparent;
  background: ${({ hasBgColor }) =>
    (hasBgColor === 'blue' && 'var(--blue)') ||
    (hasBgColor === 'purple' && 'var(--purple)') ||
    (hasBgColor === 'green' && 'var(--green)') ||
    (hasBgColor === 'pink' && 'var(--pink)') ||
    'var(--blue)'};

  ${media.TABLET`
    margin-bottom: calc(var(--xl) * 2);
    padding: calc(var(--xl) * 2) 0 0;
  `}
`

export const InViewAnimationContainer = styled(InViewAnimation)`
  width: 100%;
  margin-bottom: var(--xl);

  ${media.TABLET`
    margin-bottom: calc(var(--xl) * 2);
  `}
`
