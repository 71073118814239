import styled from 'styled-components'
import media from 'constants/breakpoints'

export const Wrap = styled.div`
  width: 100%;
  padding-right: var(--sm);
  padding-left: var(--sm);
  margin: 0 auto var(--xl) auto;

  ${media.TABLET`
    width: 83.33333%;
    padding: 0;
    margin-bottom: calc(var(--xl) * 2);
  `}

  p {
    margin-bottom: var(--md);

    &:last-child {
      margin-bottom: 0;
    }
  }
`
