import React from 'react'
import PropTypes from 'prop-types'
import Container from 'components/container'
import { Title, Copy } from 'components/typography'
import { Flex, LinkContainer, ExternalLink } from './projectHeader.css'

const ProjectHeader = ({ heading, subheading, disciplines, projectLink = false, projectLinkCopy, projectUrl }) => (
  <Container>
    <Flex>
      <div>
        <Title as="h1" size="level3">
          {heading}
        </Title>
        <Copy as="span" size="base" weight="light">
          {subheading}
        </Copy>
      </div>
      {projectLink === true ? (
        <LinkContainer>
          <ExternalLink href={projectUrl} target="_blank" rel="noopener noreferrer">
            {projectLinkCopy}
          </ExternalLink>
        </LinkContainer>
      ) : null}
      <ul>
        {disciplines.map((item, i) => (
          <Copy as="li" size="tiny" weight="light" key={i}>
            {item}
          </Copy>
        ))}
      </ul>
    </Flex>
  </Container>
)

ProjectHeader.propTypes = {
  heading: PropTypes.string.isRequired,
  subheading: PropTypes.string.isRequired,
  disciplines: PropTypes.arrayOf(PropTypes.string).isRequired,
  projectLink: PropTypes.bool,
  projectLinkCopy: PropTypes.string,
  projectUrl: PropTypes.string
}

export default ProjectHeader
