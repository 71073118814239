import styled from 'styled-components'
import media from 'constants/breakpoints'
import { Link } from 'gatsby'
import github from 'images/github.svg'

export const StyledFooter = styled.footer`
  padding: var(--md);
  margin-bottom: var(--sm);
  display: flex;
  width: 100%;
  text-align: center;

  ${media.DESKTOP`
    margin-bottom: var(--lg);
  `}
`

export const List = styled.ul`
  width: 100%;
  display: inline-flex;
  justify-content: center;
  flex-wrap: wrap;
  letter-spacing: 0.3px;
`

export const Item = styled.li`
  &:first-child::after {
    display: inline-block;
    content: '|';
    margin: 0 8px;
  }

  /* &::before {
    display: inline-block;
    content: '🍪';
    margin-right: 4px;
    height: 25px;
  }

  &:last-child::before {
    display: inline-block;
    height: 30px;
    content: url(${github});
    vertical-align: middle;
    margin-right: 4px;
  } */
`

export const ExternalLink = styled.a`
  font-size: var(--text-sm);

  ::after {
    content: '';
    display: block;
    width: 100%;
    height: 1px;
    background: var(--neutral900);
    transform: scaleX(0) translateZ(0);
    margin-top: -2px;
    transition: transform 0.31s cubic-bezier(0.165, 0.84, 0.44, 1);
    transform-origin: right;
  }

  &:hover::after {
    transform: scaleX(1) translateZ(0);
    transform-origin: left;
  }
`

export const StyledLink = styled(Link)`
  font-size: var(--text-sm);

  ::after {
    content: '';
    display: block;
    width: 100%;
    height: 1px;
    background: var(--neutral900);
    transform: scaleX(0) translateZ(0);
    margin-top: -2px;
    transition: transform 0.31s cubic-bezier(0.165, 0.84, 0.44, 1);
    transform-origin: right;
  }

  &:hover::after {
    transform: scaleX(1) translateZ(0);
    transform-origin: left;
  }
`
