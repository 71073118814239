import styled from 'styled-components'
import media from 'constants/breakpoints'
import { Link } from 'gatsby'

export const Section = styled.section`
  width: 100%;
  height: calc(100vh - 92px);
  display: flex;
  align-content: center;
  justify-content: center;

  ${media.DESKTOP`
    height: calc(100vh - 128px);
  `}
`

export const StyledLink = styled(Link)`
  display: flex;
  align-items: center;
  padding: var(--md);
  margin: auto;
  transform: translateY(64px);

  img {
    margin-left: 8px;
    transform: translateX(0);
    transition: transform 0.31s cubic-bezier(0.165, 0.84, 0.44, 1);
  }

  :hover {
    img {
      transform: translateX(4px);
    }
  }
`
