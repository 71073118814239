import React from 'react'
import PropTypes from 'prop-types'
import arrow from 'images/arrow.svg'
import { Title } from 'components/typography'
import { Section, StyledLink as Link } from './projectFooter.css'

const ProjectFooter = ({ link }) => (
  <Section>
    <Link to={link} >
      <Title size="level2">Next Project</Title>
      <img src={arrow} alt="Next Project" />
    </Link>
  </Section>
)

ProjectFooter.propTypes = {
  link: PropTypes.string.isRequired,
}

export default ProjectFooter
