import React from 'react'
import PropTypes from 'prop-types'
import Container from 'components/container'
import { FlexContainer } from './threeColumn.css'

const ThreeColumn = ({ children }) => (
  <Container>
    <FlexContainer>{children}</FlexContainer>
  </Container>
)

ThreeColumn.propTypes = {
  children: PropTypes.node.isRequired
}

export default ThreeColumn
