import styled from 'styled-components'
import media from 'constants/breakpoints'

export const Flex = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  padding-right: var(--sm);
  padding-left: var(--sm);
  margin: 0 auto var(--xl) auto;

  span {
    display: block;
    max-width: 344px;
    margin-top: 16px;
  }

  ul {
    margin-top: 24px;
  }

  ${media.TABLET`
    width: 83.33333%;
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-end;
    flex-wrap: wrap;
    padding: 0;
    margin-bottom: 128px;

    ul {
      text-align: right;
      margin-top: 0;
      padding-bottom: 2px;
    }
  `}
`

export const LinkContainer = styled.div`
  margin-top: 16px;
  margin-bottom: 8px;

  ${media.TABLET`
    order: 3;
    flex-basis: 100%;
  `}
`

export const ExternalLink = styled.a`
  position: relative;
  text-decoration: none;
  font-weight: 300;

  ::after {
    content: '';
    display: block;
    position: absolute;
    top: calc(1.5em + 3px);
    width: 100%;
    height: 1px;
    background: var(--neutral500);
    transform: scaleX(1) translateZ(0);
    transition: transform 0.31s cubic-bezier(0.165, 0.84, 0.44, 1);
    transform-origin: left;
  }

  &:hover::after {
    transform: scaleX(0) translateZ(0);
    transform-origin: right;
  }
`
