import React from 'react'
import PropTypes from 'prop-types'
import Container from 'components/container'
import { Copy } from 'components/typography'
import { Wrap } from './textBlock.css'

const TextBlock = ({ copy }) => (
  <Container>
    <Wrap>
      {copy.map((item, i) => (
        <Copy as="p" weight="light" key={i}>
          {item}
        </Copy>
      ))}
    </Wrap>
  </Container>
)

TextBlock.propTypes = {
  copy: PropTypes.arrayOf(PropTypes.string).isRequired
}

export default TextBlock
