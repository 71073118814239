import React from 'react'
import { StyledFooter, List, Item, ExternalLink } from './footer.css'

const Footer = () => (
  <StyledFooter>
    <List>
      <Item>
        <ExternalLink href="mailto:hello@matbrewer.io">hello@matbrewer.io</ExternalLink>
      </Item>
      <Item>
        <ExternalLink href="https://www.linkedin.com/in/matbrewer" target="_blank" rel="noopener noreferrer">linked.in/matbrewer</ExternalLink>
      </Item>
    </List>
  </StyledFooter>
)

export default Footer
