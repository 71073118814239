import styled from 'styled-components'
import media from 'constants/breakpoints'

export const FlexContainer = styled.div`
  display: flex;
  flex-wrap: nowrap;
  flex-direction: column;

  > div {
    // padding-left: 0;
    // padding-right: 0;
  }

  ${media.TABLET`
    flex-direction: row;
    justify-content: space-between;

    > div {
      margin-right: 5%;

      &:last-child {
        margin-right: 0;
      }

      &:nth-child(odd) {
        margin-top: calc(var(--xl) * 2)
      }
    }
  `}
`
