import React from 'react'
import PropTypes from 'prop-types'
import { GatsbyImage, getImage } from 'gatsby-plugin-image'
import Container from 'components/container'
import { Background, InViewAnimationContainer } from './imageBlock.css'

const ImageBlock = ({ src, alt, hasBgColor, noPadding }) => {
  const image = getImage(src)

  if (hasBgColor) {
    return (
      <Background hasBgColor={hasBgColor}>
        <Container>
          <InViewAnimationContainer>
            <GatsbyImage image={image} alt={alt} />
          </InViewAnimationContainer>
        </Container>
      </Background>
    )
  } else {
    return (
      <Container noPadding={noPadding}>
        <InViewAnimationContainer>
          <GatsbyImage image={image} alt={alt} />
        </InViewAnimationContainer>
      </Container>
    )
  }
}

ImageBlock.propTypes = {
  src: PropTypes.object.isRequired,
  alt: PropTypes.string.isRequired,
  hasBgColor: PropTypes.oneOfType([PropTypes.string, PropTypes.bool])
}

export default ImageBlock
